import React from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
export default function Roadmap() {
  return (
    <div>
      <Header />
      <div className="max-w-7xl mx-auto xl:px-4">
        <h1 className="uppercase font-MonumentExtendedUltrabold text-[80px] md:text-2xl text-center mt-32 md:mt-10 text-nowrap xl:text-wrap px-4">
          How to get boy’s club
        </h1>
        <h2 className="uppercase text-center font-NeueMachinabold text-[20px] md:text-lg mx-w-3xl mt-10 leading-10 px-4">
          boy’s club is on The Layer 2 blockchain by Coinbase. You’ll need to
          add Base Network to your <br />
          <span className="text-nowrap xl:text-wrap">
            wallet an bridge ETH in order to come over! It’s very easy and we’ll
            guide you through it in few simple steps!
          </span>
        </h2>

        <div className="flex xl:flex-col items-center mt-20 ">
          <div className="w-1/2 xl:w-full ">
            <div className="px-10">
              <div className="bg-primary flex justify-center items-center gap-3 w-fit p-2 ml-auto  xl:mx-auto mt-10">
                <h1 className="bg-black p-4 font-MonumentExtended">01</h1>
                <h1 className="text-black font-NeueMachinabold text-[27px] uppercase">
                  step one
                </h1>
              </div>
              <img
                src="/assets/images/animated_sticker 1.png"
                alt="animated_sticker"
                className="xl:my-4 xl:mx-auto"
              />
            </div>
          </div>
          <div className="w-1/2 xl:w-full border-l-2 border-primary/25 border-dashed relative xl:border-none">
            <div>
              <img
                src="/assets/images/dot.png"
                alt="Dot"
                srcset=""
                className="-ml-2.5 xl:hidden "
              />
            </div>
            <div className="mx-10 md:mx-0 gradiant-roadmap-box">
              <p className="font-NeueMachinabold text-lg md:text-base leading-10 p-5 text-wrap break-words lg:mt-10">
                Go to ‘Settings’ in your wallet and find the networks section.
                <br />
                Add a network manually and enter the following info for Base:
                Network Name: BaseRPC Endpoint: https://mainnet.base.org/Chain
                ID: 8453Currency Symbol: ETHBlock Explorer: https://basescan.org
                CONNECT TO BASE Once saved, you should be able to connect to
                Base by selecting it from the network selection menu.
              </p>
            </div>
          </div>
        </div>

        <div className="flex xl:flex-col-reverse items-center  -mt-8 xl:mt-36">
          <div className="w-1/2 xl:w-full ">
            <div className="mx-10 md:mx-0 gradiant-roadmap-box mt-36  xl:mt-10 ">
              <p className="font-NeueMachinabold text-lg md:text-base leading-8 p-5 text-wrap break-words ">
                Now you’ll need to deposit some ETH onto Base! Here you have a
                couple options: 1. Withdraw ETH to your Base wallet from
                Coinbase, Binance, Byit or OKX Make sure to select Base as the
                withdrawal network! 2. Bridge ETH to Base from another chain
                using these bridges: <br />- Official Base Bridge
                https://bridge.base.org/deposit,
                <br />- Orbiter https://www.orbiter.finance/,
                <br />- Stargate https://stargate.finance/, To use these bridges
                simply connect your wallet, select the network you want to
                bridge from, choose Base as the network to bridge to, and input
                the amount of ETH you would like to bridge!
              </p>
            </div>
          </div>
          <div className="w-1/2 xl:w-full border-l-2 border-primary/25 border-dashed relative xl:border-none">
            <div>
              <img
                src="/assets/images/dot.png"
                alt="Dot"
                srcset=""
                className="-ml-2.5 mt-36 xl:hidden "
              />
            </div>
            <div className="px-10">
              <div className="bg-primary flex justify-center items-center gap-3 w-fit p-2 mr-auto  xl:mx-auto xl:mt-10">
                <h1 className="bg-black p-4 font-MonumentExtended">02</h1>
                <h1 className="text-black font-NeueMachinabold text-[27px] uppercase">
                  step two
                </h1>
              </div>
              <img
                src="/assets/images/image 11.png"
                alt="animated_sticker"
                className="xl:my-4 ml-auto xl:mx-auto"
              />
            </div>
          </div>
        </div>

        <div className="flex xl:flex-col items-center  ">
          <div className="w-1/2 xl:w-full h-full">
            <div className="px-10 pt-36">
              <div className="bg-primary flex justify-center items-center gap-3 w-fit p-2 ml-auto  xl:mx-auto">
                <h1 className="bg-black p-4 font-MonumentExtended">03</h1>
                <h1 className="text-black font-NeueMachinabold text-[27px] uppercase">
                  step three
                </h1>
              </div>
              <img
                src="/assets/images/animated_sticker (3).png"
                alt="animated_sticker"
                className="xl:my-4 mr-auto xl:mx-auto"
              />
            </div>
          </div>
          <div className="w-1/2 xl:w-full h-full border-l-2 border-primary/25 border-dashed relative xl:border-none pb-36">
            <div>
              <img
                src="/assets/images/dot.png"
                alt="Dot"
                srcset=""
                className="-ml-2.5 mt-36 xl:hidden "
              />
            </div>
            <div className="mx-10 md:mx-0 gradiant-roadmap-box  xl:mt-10">
              <p className="font-NeueMachinabold text-lg md:text-base leading-8 p-5 text-wrap break-words ">
                1. In your wallet, switch the network to Base in the network
                dropdown menu <br />
                2. Go to https://www.sushi.com/swap <br /> 3. Connect your
                wallet <br /> 4. In the bottom swap field, select a token and
                input the official $MOCHI token
                address 0xF6e932Ca12afa26665dC4dDE7e27be02A7c02e50
              </p>
            </div>
          </div>
        </div>

        <div className="flex xl:flex-col-reverse items-center  xl:mb-20">
          <div className="w-1/2 xl:w-full h-full">
            <div className="mx-10 md:mx-0 gradiant-roadmap-box -mt-36">
              <p className="font-NeueMachinabold text-lg md:text-base leading-8 p-5 text-wrap break-words md:mt-10">
                Perform the swap. <br /> 1. Enter an amount of ETH <br /> 2. Set
                the slippage to 3-4% by clicking on the settings wheel and
                adjusting it <br /> 3. Press Swap <br /> 4. Confirm the
                transaction in your wallet
              </p>
            </div>
          </div>
          <div className="w-1/2 xl:w-full h-full border-l-2 border-primary/25 border-dashed relative xl:border-none pb-36">
            <div>
              <img
                src="/assets/images/dot.png"
                alt="Dot"
                srcset=""
                className="-ml-2.5 mt-36 xl:hidden "
              />
            </div>
            <div className="px-10 ">
              <div className="bg-primary flex justify-center items-center gap-3 w-fit p-2 mr-auto  xl:mx-auto">
                <h1 className="bg-black p-4 font-MonumentExtended">04</h1>
                <h1 className="text-black font-NeueMachinabold text-[27px] uppercase">
                  step Four
                </h1>
              </div>
              <img
                src="/assets/images/animated_sticker.png"
                alt="animated_sticker"
                className="xl:my-4 ml-auto xl:mx-auto"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
