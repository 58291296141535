import React from "react";
import PrimaryLayout from "./components/PrimaryLayout/PrimaryLayout";

const Bedgepulsechain = () => {
  return (
    <PrimaryLayout>
      <div className="flex xl:flex-col justify-start items-start max-w-[1500px] mx-auto relative gap-10 my-20 xxl:px-4">
        <img
          src="/assets/images/roadmapbg.png"
          alt=""
          srcset=""
          className="absolute right-0 inset-0"
        />
        <div className="w-1/2 xl:w-full relative z-10">
          <h1 className="font-MonumentExtendedUltrabold text-[70px] md:text-3xl uppercase xl:text-center">
            How to bridge the{" "}
          </h1>{" "}
          <div className="flex xxxs:flex-col justify-start items-center gap-2 xl:justify-center">
            <span className="font-MonumentExtendedUltrabold text-[70px] md:text-3xl  uppercase">
              pluschain
            </span>
            <span>
              <img
                src="/assets/images/Pulsechain.png"
                alt=""
                srcset=""
                className="size-24 flex md:size-12"
              />
            </span>
          </div>
          <h2 className="mt-5 font-NeueMachinabold  uppercase leading-10 md:leading-5  xl:text-center relative z-10">
            Lorem Ipsum is simply dummy text of the <br /> printing and
            typesetting industry. Lorem <br /> Ipsum has been the industry's .
          </h2>
          <img
            src="/assets/images/phone 1.png"
            alt=""
            srcset=""
            className="w-full h-full xl:mx-auto z-10 relative xl:size-1/2 md:!size-full"
          />
        </div>

        <div className="w-1/2 xl:w-full">
          <div className="flex xl:flex-col items-center ">
            <div className="w-full xl:w-full border-l-2 border-primary/25 border-dashed relative xl:border-none">
              <img
                src="/assets/images/phase1.png"
                alt="Dot"
                srcset=""
                className="xl:flex hidden mx-auto my-10"
              />
              <div className="">
                <img
                  src="/assets/images/phase1.png"
                  alt="Dot"
                  srcset=""
                  className="-ml-2.5 xl:hidden absolute top-0 -left-14"
                />
                <div className="my-1 h-px border-2 border-primary/25 border-dashed absolute top-10 w-10 left-11 xl:hidden" />
              </div>
              <div className="mx-10 md:mx-0 gradiant-roadmap-box rounded-[30px] ms-20 xl:ms-0  relative">
                <p className="font-NeueMachinabold  text-lg md:text-base leading-10 p-5 text-wrap break-words lg:mt-10">
                  <ul className="list-inside list-disc">
                    <li>
                      {" "}
                      Go to ‘Settings’ in your wallet and find the networks
                      section.
                    </li>
                    <li>
                      {" "}
                      Network Name: BaseRPC
                      Endpoint: https://mainnet.base.org/Chain ID: 8453Currency
                      Symbol: ETHBlock Explorer: https://basescan.org
                    </li>
                    <li> CONNECT TO BASE</li>
                    <li>
                      Once saved, you should be able to connect to Base by
                      selecting it from the network selection menu.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>

          <div className="flex xl:flex-col-reverse items-center  -mt-8 xl:mt-20 relative border-l-2 border-primary/25 border-dashed xl:border-none">
            <div className="">
              <img
                src="/assets/images/phase2.png"
                alt="Dot"
                srcset=""
                className="-ml-2.5 xl:hidden absolute top-32 z-10 -left-14"
              />
            </div>
            <div className="w-full xl:w-full ">
              <div className="mx-10 md:mx-0 relative gradiant-roadmap-box rounded-[30px] ms-20 xl:ms-0 mt-36  xl:mt-0 ">
                <div className="my-1 h-px border-2 border-primary/25 border-dashed absolute top-10  w-10 -left-11 xl:hidden" />
                <ul className="list-inside list-disc font-NeueMachinabold  text-lg md:text-base leading-8 p-5 text-wrap break-words ">
                  <li>
                    {" "}
                    Now you’ll need to deposit some ETH onto Base! Here you have
                    a couple options:
                  </li>
                  <li>
                    {" "}
                    1. Withdraw ETH to your Base wallet from Coinbase, Binance,
                    Byit or OKX Make sure to select Base as the withdrawal
                    network!
                  </li>
                  <li>
                    {" "}
                    2. Bridge ETH to Base from another chain using these
                    bridges:- Official Base
                    Bridge https://bridge.base.org/deposit,-
                    Orbiter https://www.orbiter.finance/,-
                    Stargate https://stargate.finance/,
                  </li>
                  <li>
                    To use these bridges simply connect your wallet, select the
                    network you want to bridge from, choose Base as the network
                    to bridge to, and input the amount of ETH you would like to
                    bridge!
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <img
                src="/assets/images/phase2.png"
                alt="Dot"
                srcset=""
                className="xl:flex hidden mx-auto my-10"
              />
            </div>
          </div>
          <div className="flex xl:flex-col-reverse items-center relative -mt-8 xl:mt-20  border-l-2 border-primary/25 border-dashed xl:border-none">
            <div>
              <img
                src="/assets/images/phase3.png"
                alt="Dot"
                srcset=""
                className="-ml-2.5 xl:hidden absolute top-32 z-10 -left-14"
              />
            </div>
            <div className="w-full xl:w-full ">
              <div className="mx-10 md:mx-0 gradiant-roadmap-box relative rounded-[30px] ms-20 xl:ms-0 mt-36  xl:mt-0 ">
                <div className="my-1 h-px border-2 border-primary/25 border-dashed absolute top-10  w-10 -left-11 xl:hidden" />

                <p className="font-NeueMachinabold  text-lg md:text-base leading-8 p-5 text-wrap break-words ">
                  <ul className="list-inside list-disc">
                    <li>
                      {" "}
                      1. In your wallet, switch the network to Base in the
                      network dropdown menu{" "}
                    </li>
                    <li> 2. Go to https://www.sushi.com/swap</li>
                    <li> 3. Connect your wallet</li>
                    <li>
                      4. In the bottom swap field, select a token and input the
                      official $MOCHI token
                      address 0xF6e932Ca12afa26665dC4dDE7e27be02A7c02e50
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            <div>
              <img
                src="/assets/images/phase3.png"
                alt="Dot"
                srcset=""
                className="xl:flex hidden mx-auto my-10"
              />
            </div>
          </div>
          <div className="flex xl:flex-col-reverse items-center relative -mt-8 xl:mt-20  border-l-2 border-primary/25 border-dashed xl:border-none">
            <div>
              <img
                src="/assets/images/phase4.png"
                alt="Dot"
                srcset=""
                className="-ml-2.5 xl:hidden absolute top-32 z-10 -left-14"
              />
            </div>
            <div className="w-full xl:w-full uppercase">
              <div className="mx-10 md:mx-0 gradiant-roadmap-box relative rounded-[30px] ms-20 xl:ms-0 mt-36  xl:mt-0 ">
                <div className="my-1 h-px border-2 border-primary/25 border-dashed absolute top-10  w-10 -left-11 xl:hidden" />

                <p className="font-NeueMachinabold  text-lg md:text-base leading-8 p-5 text-wrap break-words ">
                  Perform the swap.
                  <ul className="list-inside list-disc">
                    <li>1. Enter an amount of ETH </li>
                    <li> 2. Set the</li>
                    <li>
                      {" "}
                      slippage to 3-4% by clicking on the settings wheel and
                      adjusting it{" "}
                    </li>
                    <li>3. Press Swap </li>
                    <li> 4. Confirm the transaction in your wallet</li>
                  </ul>
                </p>
              </div>
            </div>
            <div>
              <img
                src="/assets/images/phase4.png"
                alt="Dot"
                srcset=""
                className="xl:flex hidden mx-auto my-10"
              />
            </div>
          </div>
        </div>
      </div>{" "}
      *
    </PrimaryLayout>
  );
};

export default Bedgepulsechain;
