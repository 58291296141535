import React, { useEffect, useState } from "react";

export default function Hero() {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="min-h-screen hero bg-center bg-cover md:pt-20 relative">
      {/* <img
        src="/assets/images/Union (1).png"
        alt="union"
        className={`${
          isVisible ? "!absolute" : "hidden"
        } right-[20%] -top-5 !z-50`}
      /> */}
      <img
        src="/assets/images/heroboy.png"
        alt="heroboy poster"
        srcset=""
        className="mx-auto size-1/2 z-10"
      />
      <div className="flex md:flex-col -mt-10 xl:mt-0  items-center mx-auto justify-center gap-5 font-NeueMachinaregular uppercase">
        {/* <div className="  py-4 px-10 md:px-5 md:py-2  text-white rounded-[15px] herobtn cursor-pointer ">
          BUY (9 MM)
        </div>
        <div className="  py-4 px-10 md:px-5 md:py-2  text-white rounded-[15px]  herobtn cursor-pointer ">
          CHART INVESTDEX
        </div> */}
        <a
          href="/pulsechain"
          className="text-black  py-3 px-10 md:px-5 md:py-2 bg-[#78c135] cursor-pointer rounded-[20px]"
        >
          BUY
        </a>
        {/* <div className="text-black  py-3 px-10 md:px-5 md:py-2 bg-[#78c135] cursor-pointer rounded-[20px]">
          CHART
        </div> */}
      </div>
      {/* <h1 className="text-center text-white -mt-10 xl:mt-0 uppercase font-MonumentExtended text-[30px] z-10">
        a multi chain project by : omni labz
      </h1> */}
    </div>
  );
}
