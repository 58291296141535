import React from "react";
import Social from "./components/Social/Social";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import PrimaryLayout from "./components/PrimaryLayout/PrimaryLayout";

export default function Contact() {
  return (
    <PrimaryLayout>
      <div className="flex flex-col justify-center">
        <Social />
      </div>
    </PrimaryLayout>
  );
}
