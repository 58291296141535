import React from "react";
import PepeDetail from "./components/Pepe/PepeDetail";

export const Pepe = () => {
  return (
    <div>
      <PepeDetail />
    </div>
  );
};
