import React from "react";
import { FaTwitter, FaDiscord, FaTelegramPlane } from "react-icons/fa";
export default function Footer() {
  return (
    <div className="w-full mb-10 ">
      <div className="bg-primary -rotate-1 xxl:px-4 md:py-2 ">
        <div className="max-w-[1500px] mx-auto text-black flex justify-between items-center">
          <div>
            <a href="/">
              <img
                src="/assets/images/BoysClub_01-1 1.png"
                alt="BoysClub_01"
                srcset=""
                className="md:size-[90%]"
              />
            </a>
          </div>
          <div className="flex items-center gap-10 md:gap-3 font-NeueMachinaregular text-[24px] md:text-base uppercase">
            <a
              href="https://x.com/boysclubpls"
              target="_blank"
              className="flex items-center gap-2 cursor-pointer"
            >
              <FaTwitter fontSize={24} />
              <h1>twitter</h1>
            </a>
            <a
              href="https://t.me/boysclubpulse"
              target="_blank"
              className="flex items-center gap-2 cursor-pointer"
            >
              <FaTelegramPlane fontSize={24} />
              <h1>telegram</h1>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
