import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const PrimaryLayout = ({ children }) => {
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <header>
        <Header />
      </header>
      <main className="max-w-[1500px] mx-auto pt-[138px] md:pt-[97px]">
        {children}
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default PrimaryLayout;
