import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Roadmap from "./Roadmap";
import Contact from "./Contact";
import RoadmapSecond from "./components/roadmap2/RoadmapSecond";
import Base from "./components/Base/Base";
import Andy from "./Andy";
import Breet from "./Breet";
import { Pepe } from "./Pepe";
import Landwolf from "./Landwolf";
import Bdog from "./Bdog";
import Bedgepulsechain from "./Bedgepulsechain";
import Bridgebasechain from "./Bridgebasechain";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/roadmap" element={<Roadmap />} />
        <Route path="/roadmap/" element={<Roadmap />} />
        <Route path="/contact/" element={<Contact />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/andy" element={<Andy />} />
        <Route path="/breet" element={<Breet />} />
        <Route path="/pepe" element={<Pepe />} />
        <Route path="/landwolf" element={<Landwolf />} />
        <Route path="/birddog" element={<Bdog />} />

        <Route path="/pulsechain" element={<RoadmapSecond />} />
        <Route path="/base" element={<Base />} />

        <Route path="/bridgepulsechain" element={<Bedgepulsechain />} />
        <Route path="/bridgebasechain" element={<Bridgebasechain />} />
      </Routes>
    </BrowserRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
