import React from "react";
import PrimaryLayout from "../PrimaryLayout/PrimaryLayout";

const LandwolfDetail = () => {
  return (
    <PrimaryLayout>
      <div className="flex  md:flex-col-reverse items-center md:items-center md:justify-center xxl:px-4 ">
        <div className="w-1/2 md:w-full md:text-center md:mb-10">
          <h1 className="uppercase font-MonumentExtended text-[64px] md:text-3xl md:mb-10 mb-10">
            landwolf
          </h1>
          <p className=" font-NeueMachinabold text-[20px] md:text-base  uppercase">
            In the whimsical world of "Boy's Club," $WOLF stands as $PEPE's best
            friend. Through their shared antics and laughs, their friendship
            blossoms, painting a picture of camaraderie amidst delightful
            absurdity. Together, $WOLF and $PEPE navigate their unique, humorous
            journey, embodying the spirit of Matt Furie's imaginative creation.
          </p>
          <p className=" font-NeueMachinabold text-[20px] md:text-base break-words uppercase mt-5">
            Official Landwolf on Pulsechain
          </p>
        </div>
        <div className="w-1/2 md:w-full">
          <img
            src="/assets/images/Landwolf_Walking_002.png"
            alt="Landwolf"
            srcset=""
            className="mt-5 md:mx-auto size-[85%] md:size-full"
          />
        </div>
      </div>
    </PrimaryLayout>
  );
};

export default LandwolfDetail;
