import React from "react";
import { CiShoppingCart } from "react-icons/ci";
import { LuPieChart } from "react-icons/lu";
import { RiTwitterXFill } from "react-icons/ri";
import { PiTelegramLogoLight } from "react-icons/pi";
import { AiOutlineMail } from "react-icons/ai";
export default function Social() {
  return (
    <>
      <h1 className="text-center uppercase text-[80px] md:text-2xl font-MonumentExtendedUltrabold">
        Get in touch with us
      </h1>
      <div className="flex flex-wrap justify-center items-center gap-5 mb-20 mt-10">
        <div className="rounded-full size-16 md:size-14 border border-primary text-primary flex justify-center items-center cursor-pointer">
          <CiShoppingCart size={30} />
        </div>
        <div className="rounded-full size-16 md:size-14 border border-primary text-primary flex justify-center items-center cursor-pointer">
          <LuPieChart size={30} />
        </div>
        <a
          href="https://x.com/boysclubpls"
          target="_blank"
          className="rounded-full size-16 md:size-14 border border-primary text-primary flex justify-center items-center cursor-pointer"
        >
          <RiTwitterXFill size={30} />
        </a>
        <a
          href="https://t.me/boysclubpulse"
          target="_blank"
          className="rounded-full size-16 md:size-14 border border-primary text-primary flex justify-center items-center cursor-pointer"
        >
          <PiTelegramLogoLight size={30} />
        </a>
        <div className="rounded-full size-16 md:size-14 border border-primary text-primary flex justify-center items-center cursor-pointer">
          <AiOutlineMail size={30} />
        </div>
      </div>
    </>
  );
}
