import React from "react";

export default function CommingSoon() {
  return (
    <div className="my-20 justify-start">
      <div className="font-MonumentExtendedUltrabold text-[70px] md:text-2xl uppercase text-gray-400/25 text-start ">
        <div className="flex flex-wrap items-center lg:justify-center text-nowrap md:text-wrap">
          Extra ordinary <br />
          <span className=" flex">
            <img
              src="/assets/images/nft-6.gif"
              alt="ezgif"
              srcset=""
              className="md:h-[50px] h-[150px]"
            />
          </span>{" "}
          NFt{" "}
        </div>
        <div className="text-end ml-auto lg:text-center ">
          art <span className="text-[#8D9F2C]/25">coming soon...</span>
        </div>
      </div>

      <div className="flex md:flex-col">
        <div>
          <img
            src="/assets/images/landworld 1.png"
            alt="landworld"
            srcset=""
            className="-mt-44 -me-20 md:mx-auto lg:mt-0 md:h-1/2 md:w-1/2"
          />
        </div>

        <div className="font-NeueMachinabold text-[20px] md:text-base uppercase">
          <div className=" mt-20 md:mt-10 ">
            <h1>NFT Collections </h1>{" "}
          </div>

          <ul className="list-disc list-inside   mt-5">
            <li>Art by Slimewhale (Concept Artist for Squanch Games)</li>
            <li className="mt-5">
              developed jointly with omni labz and slimelabz
            </li>
          </ul>
          <a href="https://t.me/boysclubpulse" target="_blank">
            <div className="text-primary  py-3 px-8 mt-10 gradient-btn font-NeueMachinaregular w-fit cursor-pointer">
              Join telegram for updates
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
