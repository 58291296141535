import React, { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { GoPlus } from "react-icons/go";
import { FiMinus } from "react-icons/fi";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
const faqData = [
  {
    title: "PEPE",
    content: (
      <>
        <div className=" flex gap-3 flex-wrap justify-center items-center">
          <div className="hover:underline text-primary  py-3 px-10 gradient-btn2 cursor-pointer">
            <a
              href="https://dexscreener.com/pulsechain/0x11659a752ac07452b9f139b897c917338e2dfb16"
              target="_blank"
            >
              DexScreener
            </a>
          </div>
          <div className="hover:underline text-primary  py-3 px-10 gradient-btn2 cursor-pointer">
            <a
              href="https://pulsex.mypinata.cloud/ipfs/bafybeiesh56oijasgr7creubue6xt5anivxifrwd5a5argiz4orbed57qi/#/?outputCurrency=0x6982508145454Ce325dDbE47a25d4ec3d2311933"
              target="_blank"
            >
              PluseX
            </a>
          </div>
        </div>
        <h1 className="mt-4 md:break-words text-center bg-[#C2C2C2]/10 rounded-[30px] p-5 mb-5">
          CA:
          <h1 className="hover:underline text-center  !rounded-[50px] bg-[#8A9454]/20 w-full mx-auto p-3 mt-3">
            0x6982508145454Ce325dDbE47a25d4ec3d2311933
          </h1>
        </h1>
      </>
    ),
  },

  {
    title: "brett",
    content: (
      <>
        <ul className="flex gap-3 flex-wrap justify-center items-center">
          <li className="hover:underline text-primary  py-3 px-10 gradient-btn2 cursor-pointer ">
            <a
              href="https://dexscreener.com/pulsechain/0x29eaceb3801457fdc316f00f70db0cb2be942927"
              target="_blank"
            >
              DexScreener
            </a>
          </li>
          <li className="hover:underline text-primary  py-3 px-10 gradient-btn2 cursor-pointer">
            <a
              href="https://pulsex.mypinata.cloud/ipfs/bafybeiesh56oijasgr7creubue6xt5anivxifrwd5a5argiz4orbed57qi/#/?outputCurrency=0x05cb9A4C644d9d6bc07430a220E1B5CE11Cd2eaa"
              target="_blank"
            >
              PluseX
            </a>
          </li>
        </ul>
        <h1 className="mt-4 md:break-words text-center bg-[#C2C2C2]/10 rounded-[30px] p-5 mb-5">
          CA:
          <h1 className="hover:underline text-center  !rounded-[50px] bg-[#8A9454]/20 w-full mx-auto p-3 mt-3">
            0x05cb9A4C644d9d6bc07430a220E1B5CE11Cd2eaa
          </h1>
        </h1>
      </>
    ),
  },
  {
    title: "andy",
    content: (
      <>
        <ul className="flex gap-3 flex-wrap justify-center items-center">
          <li className="hover:underline text-primary  py-3 px-10 gradient-btn2 cursor-pointer">
            <a
              href="https://dexscreener.com/pulsechain/0x1487106ffbc7279a8aadc435bc0c8dc58b21073b"
              target="_blank"
            >
              DexScreener
            </a>
          </li>
          <li className="hover:underline text-primary  py-3 px-10 gradient-btn2 cursor-pointer">
            <a
              href="https://pulsex.mypinata.cloud/ipfs/bafybeiesh56oijasgr7creubue6xt5anivxifrwd5a5argiz4orbed57qi/#/?outputCurrency=0x3E59B2aeAe49674CF0CB21bf200277f3CcD232DD"
              target="_blank"
            >
              PluseX
            </a>
          </li>
        </ul>
        <h1 className="mt-4 md:break-words text-center bg-[#C2C2C2]/10 rounded-[30px] p-5 mb-5">
          CA:
          <h1 className="hover:underline text-center  !rounded-[50px] bg-[#8A9454]/20 w-full mx-auto p-3 mt-3">
            0x3E59B2aeAe49674CF0CB21bf200277f3CcD232DD
          </h1>
        </h1>
      </>
    ),
  },
  {
    title: "landwolf",
    content: (
      <>
        <ul className="flex gap-3 flex-wrap justify-center items-center">
          <li className="hover:underline text-primary  py-3 px-10 gradient-btn2 cursor-pointer">
            <a
              href="https://dexscreener.com/pulsechain/0x794f1c6e67f78ef5593ae9dced56defd2bac0ce0"
              target="_blank"
            >
              DexScreener
            </a>
          </li>
          <li className="hover:underline text-primary  py-3 px-10 gradient-btn2 cursor-pointer">
            <a
              href="https://pulsex.mypinata.cloud/ipfs/bafybeiesh56oijasgr7creubue6xt5anivxifrwd5a5argiz4orbed57qi/#/?outputCurrency=0xa219C24353B59bEE9F535C4402c11C476A49ACA9"
              target="_blank"
            >
              PluseX
            </a>
          </li>
        </ul>
        <h1 className="mt-4 md:break-words text-center bg-[#C2C2C2]/10 rounded-[30px] p-5 mb-5">
          CA:
          <h1 className="hover:underline text-center  !rounded-[50px] bg-[#8A9454]/20 w-full mx-auto p-3 mt-3">
            0xa219C24353B59bEE9F535C4402c11C476A49ACA9
          </h1>
        </h1>
      </>
    ),
  },
  {
    title: "bird dog",
    content: (
      <>
        <ul className="flex gap-3 flex-wrap justify-center items-center">
          <li className="hover:underline text-primary  py-3 px-10 gradient-btn2 cursor-pointer">
            <a
              href="https://dexscreener.com/pulsechain/0xfd1da1b9ccfd8ab9b8477711849e904eae485b25"
              target="_blank"
            >
              DexScreener
            </a>
          </li>
          <li className="hover:underline text-primary  py-3 px-10 gradient-btn2 cursor-pointer">
            <a
              href="https://pulsex.mypinata.cloud/ipfs/bafybeiesh56oijasgr7creubue6xt5anivxifrwd5a5argiz4orbed57qi/#/?outputCurrency=0x64Cdf1E81136023ad912ED1522309fDa05322187"
              target="_blank"
            >
              PluseX
            </a>
          </li>
        </ul>
        <h1 className="mt-4 md:break-words text-center bg-[#C2C2C2]/10 rounded-[30px] p-5 mb-5">
          CA:
          <h1 className="hover:underline text-center  !rounded-[50px] bg-[#8A9454]/20 w-full mx-auto p-3 mt-3">
            0x64Cdf1E81136023ad912ED1522309fDa05322187
          </h1>
        </h1>
      </>
    ),
  },
];
export default function Faq() {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleClick = (index) => {
    if (selectedIndex !== index) {
      setSelectedIndex(index);
    } else {
      setSelectedIndex(null); // Close the currently open disclosure
    }
  };
  return (
    <div>
      <div className="flex xl:flex-col xl:gap-5 justify-between items-center font-MonumentExtendedUltrabold my-20">
        <div className=" text-[80px] md:text-2xl uppercase">Pulsechain</div>
        <div>
          <img
            src="/assets/images/Pulsechain.png"
            alt="Pulsechain"
            srcset=""
            className="size-24"
          />
        </div>
        {/* <div className="flex items-center gap-5 font-NeueMachinaregular uppercase">
          <a
            href="https://x.com/boysclubpls"
            target="_blank"
            className="text-primary  py-3 px-10 gradient-btn2 cursor-pointer"
          >
            X.com
          </a>
          <a
            href="https://t.me/boysclubpulse"
            target="_blank"
            className="text-primary  py-3 px-10 gradient-btn2 cursor-pointer"
          >
            Telegram
          </a>
        </div> */}
        <div className="flex items-center gap-5 font-NeueMachinaregular uppercase">
          <a
            href="https://x.com/boysclubpls"
            target="_blank"
            className="text-primary  py-3 px-10 gradient-btn cursor-pointer"
          >
            X.com
          </a>
          <a
            href="https://t.me/boysclubpulse"
            target="_blank"
            className="text-primary  py-3 px-10 gradient-btn cursor-pointer"
          >
            Telegram
          </a>
        </div>
      </div>

      {/* faq */}
      {/* <div className="w-full   md:pt-0 rounded-[20px]">
        <div className="relative mx-auto w-full rounded-[20px]  gradiant-faq-box  p-10 font-NeueMachinabold uppercase">
          <img
            src="/assets/images/Ellipse.png"
            alt="Ellipse"
            srcset=""
            className="absolute inset-0 object-cover h-full w-full -z-10"
          />
          <Disclosure as="div" className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between items-center mt-5 gradiant-bg p-6 text-left text-sm font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                  <span className="text-2xl md:text-xl uppercase"> PEPE</span>

                  {open ? (
                    <FiMinus
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 `}
                    />
                  ) : (
                    <GoPlus
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 `}
                    />
                  )}
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pb-2 pt-4 text-base text-white gradiant-bg">
                  <ul className="flex gap-3 flex-wrap justify-center items-center">
                    <li className="hover:underline">
                      <a
                        href="https://dexscreener.com/pulsechain/0x11659a752ac07452b9f139b897c917338e2dfb16"
                        target="_blank"
                      >
                        DexScreener
                      </a>
                    </li>
                    <li className="hover:underline">
                      <a
                        href="https://pulsex.mypinata.cloud/ipfs/bafybeiesh56oijasgr7creubue6xt5anivxifrwd5a5argiz4orbed57qi/#/?outputCurrency=0x6982508145454Ce325dDbE47a25d4ec3d2311933"
                        target="_blank"
                      >
                        PluseX
                      </a>
                    </li>
                    <li className="hover:underline">CA:</li>
                  </ul>
                  <h1 className="mt-4 md:break-words">
                    0x6982508145454Ce325dDbE47a25d4ec3d2311933
                  </h1>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between items-center gradiant-bg p-6 text-left text-sm font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                  <span className="text-2xl md:text-xl uppercase">
                    {" "}
                    Discord
                  </span>
                  {open ? (
                    <FiMinus
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 `}
                    />
                  ) : (
                    <GoPlus
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 `}
                    />
                  )}
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500 gradiant-bg">
                  If you're unhappy with your purchase for any reason, email us
                  within 90 days and we'll refund you in full, no questions
                  asked.
                </Disclosure.Panel>
              </>
            )}

            <Disclosure as="div" className="mt-2">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between items-center mt-5 gradiant-bg p-6 text-left text-sm font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                    <span className="text-2xl md:text-xl uppercase">
                      {" "}
                      brett
                    </span>
                    {open ? (
                      <FiMinus
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 `}
                      />
                    ) : (
                      <GoPlus
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 `}
                      />
                    )}
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pb-2 pt-4 text-base text-white gradiant-bg">
                    <ul className="flex gap-3 flex-wrap justify-center items-center">
                      <li className="hover:underline">
                        <a
                          href="https://dexscreener.com/pulsechain/0x29eaceb3801457fdc316f00f70db0cb2be942927"
                          target="_blank"
                        >
                          DexScreener
                        </a>
                      </li>
                      <li className="hover:underline">
                        <a
                          href="https://pulsex.mypinata.cloud/ipfs/bafybeiesh56oijasgr7creubue6xt5anivxifrwd5a5argiz4orbed57qi/#/?outputCurrency=0x05cb9A4C644d9d6bc07430a220E1B5CE11Cd2eaa"
                          target="_blank"
                        >
                          PluseX
                        </a>
                      </li>
                      <li className="hover:underline">CA:</li>
                    </ul>
                    <h1 className="mt-4 md:break-words">
                      0x05cb9A4C644d9d6bc07430a220E1B5CE11Cd2eaa
                    </h1>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between items-center mt-5 gradiant-bg p-6 text-left text-sm font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                    <span className="text-2xl md:text-xl uppercase"> andy</span>
                    {open ? (
                      <FiMinus
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 `}
                      />
                    ) : (
                      <GoPlus
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 `}
                      />
                    )}
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pb-2 pt-4 text-base text-white gradiant-bg">
                    <ul className="flex gap-3 justify-center items-center">
                      <li className="hover:underline">
                        <a
                          href="https://dexscreener.com/pulsechain/0x1487106ffbc7279a8aadc435bc0c8dc58b21073b"
                          target="_blank"
                        >
                          DexScreener
                        </a>
                      </li>
                      <li className="hover:underline">
                        <a
                          href="https://pulsex.mypinata.cloud/ipfs/bafybeiesh56oijasgr7creubue6xt5anivxifrwd5a5argiz4orbed57qi/#/?outputCurrency=0x3E59B2aeAe49674CF0CB21bf200277f3CcD232DD"
                          target="_blank"
                        >
                          PluseX
                        </a>
                      </li>
                      <li className="hover:underline">CA:</li>
                    </ul>
                    <h1 className="mt-4 md:break-words">
                      0x3E59B2aeAe49674CF0CB21bf200277f3CcD232DD
                    </h1>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between items-center mt-5 gradiant-bg p-6 text-left text-sm font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                    <span className="text-2xl md:text-xl uppercase">
                      {" "}
                      landwolf
                    </span>
                    {open ? (
                      <FiMinus
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 `}
                      />
                    ) : (
                      <GoPlus
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 `}
                      />
                    )}
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pb-2 pt-4 text-base text-white gradiant-bg">
                    <ul className="flex gap-3 justify-center items-center">
                      <li className="hover:underline">
                        <a
                          href="https://dexscreener.com/pulsechain/0x794f1c6e67f78ef5593ae9dced56defd2bac0ce0"
                          target="_blank"
                        >
                          DexScreener
                        </a>
                      </li>
                      <li className="hover:underline">
                        <a
                          href="https://pulsex.mypinata.cloud/ipfs/bafybeiesh56oijasgr7creubue6xt5anivxifrwd5a5argiz4orbed57qi/#/?outputCurrency=0xa219C24353B59bEE9F535C4402c11C476A49ACA9"
                          target="_blank"
                        >
                          PluseX
                        </a>
                      </li>
                      <li className="hover:underline">CA:</li>
                    </ul>
                    <h1 className="mt-4 md:break-words">
                      0xa219C24353B59bEE9F535C4402c11C476A49ACA9
                    </h1>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between items-center mt-5 gradiant-bg p-6 text-left text-sm font-medium  focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                    <span className="text-2xl md:text-xl uppercase">
                      {" "}
                      bird dog
                    </span>
                    {open ? (
                      <FiMinus
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 `}
                      />
                    ) : (
                      <GoPlus
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 `}
                      />
                    )}
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pb-2 pt-4 text-base text-white gradiant-bg">
                    <ul className="flex gap-3 justify-center items-center">
                      <li className="hover:underline">
                        <a
                          href="https://dexscreener.com/pulsechain/0xfd1da1b9ccfd8ab9b8477711849e904eae485b25"
                          target="_blank"
                        >
                          DexScreener
                        </a>
                      </li>
                      <li className="hover:underline">
                        <a
                          href="https://pulsex.mypinata.cloud/ipfs/bafybeiesh56oijasgr7creubue6xt5anivxifrwd5a5argiz4orbed57qi/#/?outputCurrency=0x64Cdf1E81136023ad912ED1522309fDa05322187"
                          target="_blank"
                        >
                          PluseX
                        </a>
                      </li>
                      <li className="hover:underline">CA:</li>
                    </ul>
                    <h1 className="mt-4 md:break-words">
                      0x64Cdf1E81136023ad912ED1522309fDa05322187
                    </h1>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </Disclosure>
        </div>
      </div> */}

      <div className="w-full md:pt-0 rounded-[50px] overflow-hidden">
        <div className="relative mx-auto w-full rounded-[50px]  gradiant-faq-box  p-10 font-NeueMachinabold uppercase">
          {/* <img
            src="/assets/images/Ellipse.png"
            alt="Ellipse"
            srcset=""
            className="absolute inset-0 object-cover h-full w-full -z-10"
          /> */}
          {/* <div className=""> */}
          <img
            src="/assets/images/rightcircle.png"
            alt="Ellipse"
            srcset=""
            className="absolute right-0 top-20 object-contain object-right h-full w-full  -z-10"
          />
          <img
            src="/assets/images/leftcircle.png"
            alt="Ellipse"
            srcset=""
            className="absolute left-0  top-0   object-left object-cover h-full w-full  -z-10"
          />
          {/* </div> */}
          {faqData.map((item, index) => (
            <Disclosure key={index} as="div" className="mt-2">
              <Disclosure.Button
                onClick={() => handleClick(index)}
                className={`flex w-full ${
                  selectedIndex === index
                    ? " rounded-t-[30px]"
                    : " rounded-[30px]"
                } justify-between items-center gradiant-bg p-6 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75`}
              >
                <span className="text-2xl md:text-xl uppercase">
                  {item.title}
                </span>
                {selectedIndex === index ? (
                  <FiMinus
                    className={`${
                      selectedIndex === index ? "rotate-180 transform" : ""
                    } h-5 w-5`}
                  />
                ) : (
                  <GoPlus
                    className={`${
                      selectedIndex === index ? "rotate-180 transform" : ""
                    } h-5 w-5`}
                  />
                )}
              </Disclosure.Button>
              {selectedIndex === index && (
                <div
                  className={`${
                    selectedIndex === index
                      ? " rounded-b-[30px]"
                      : " rounded-[30px]"
                  } px-4 pb-2 pt-4 text-base text-white gradiant-bg`}
                >
                  {item.content}
                </div>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
    </div>
  );
}
