import React from "react";
import PrimaryLayout from "../PrimaryLayout/PrimaryLayout";

const BdogDetail = () => {
  return (
    <PrimaryLayout>
      <div className="flex flex-row-reverse md:flex-col-reverse items-center md:items-center md:justify-center xxl:px-4">
        <div className="w-1/2 md:w-full md:text-center md:mb-10">
          <h1 className="uppercase font-MonumentExtended text-[64px] md:text-3xl md:my-10 mb-10">
            bird dog
          </h1>
          <p className=" font-NeueMachinabold text-[20px] md:text-base  uppercase">
            In the whimsical world of "Boy's Club," Pepe and Bird Dog share a
            unique bond that goes beyond friendship. Bird-Dog, the enthusiastic
            fifth member of Pepe's gang of roommates, injects a vibrant energy
            into the group, complementing Pepe's laid-back demeanor. Their
            camaraderie is marked by shared adventures, laughter, and a mutual
            understanding that adds an extra layer of charm to Matt Furie's
            imaginative creation. Together, Pepe and Bird-Dog embark on a
            humorous journey filled with delightful absurdity, embodying the
            spirit of friendship amidst the surreal and comedic landscape of
            their world.
          </p>
          <p className=" font-NeueMachinabold text-[20px] md:text-base break-words uppercase mt-5">
            The Official Bird Dog on Pulsechain
          </p>
        </div>
        <div className="w-1/2 md:w-full">
          <img
            src="/assets/images/ezgif2.gif"
            alt="birddog"
            srcset=""
            className="mt-5 md:mx-auto md:size-1/2"
          />
        </div>
      </div>
    </PrimaryLayout>
  );
};

export default BdogDetail;
