import React from "react";

function Meet() {
  return (
    <div className="relative">
      {/* <img
        src="/assets/images/rightbg.png"
        alt="rightbg"
        srcset=""
        className="absolute right-0 object-contain  h-full -top-96  -z-10"
      />
      <img
        src="/assets/images/leftbg.png"
        alt="rightbg"
        srcset=""
        className="absolute left-0 bottom-0 inset-0 h-full -z-10"
      /> */}
      {/* 2  */}
      <img
        src="/assets/images/rightbg.png"
        alt="rightbg"
        srcset=""
        className="absolute right-0 object-contain  h-full top-0  -z-10"
      />
      <img
        src="/assets/images/leftbg.png"
        alt="rightbg"
        srcset=""
        className="absolute left-0 bottom-0 inset-0 h-full -z-10"
      />
      <div className="mb-20 max-w-7xl mx-auto xl:px-4">
        {/* pepe */}
        <div className="flex flex-row-reverse md:flex-col items-center md:items-center md:justify-center mt-20 md:mt-10">
          <div className="w-1/2 md:w-full md:text-center">
            <h1 className="uppercase font-MonumentExtended text-[64px] md:text-xl md:mb-10 mb-10">
              PEPE
            </h1>
            <p className=" font-NeueMachinabold text-[20px] md:text-base  uppercase">
              PEPE on PulseChain emerges as the ultimate memecoin, ready to claim its throne after PEPE's Ethereum success. Forked from ETH to PLS at mainnet launch, this people's coin aims to revolutionize the memecoin landscape. PEPE on PulseChain harnesses the world's most recognizable meme to reign supreme in the crypto world.
            </p>
            <p className=" font-NeueMachinabold text-[20px] md:text-base break-words uppercase mt-5">
              Official PEPE on Pulsechain
            </p>
          </div>
          <div className="w-1/2 md:w-full">
            <img
              src="/assets/images/Pepe.gif"
              alt="Line"
              srcset=""
              className="mt-5 md:mx-auto "
            />
          </div>
        </div>
        {/* Breet */}
        <div className="flex  md:flex-col items-center md:items-center md:justify-center mt-20 md:mt-10">
          <div className="w-1/2 md:w-full md:text-center">
            <h1 className="uppercase font-MonumentExtended text-[64px] md:text-xl md:mb-10 mb-10">
              Brett
            </h1>
            <p className=" font-NeueMachinabold text-[20px] md:text-base  uppercase">
              The life of the party, BRETT is known for his dance moves and carefree attitude. As a token on PulseChain, $BRETT brings the fun and excitement, encouraging holders to enjoy the crypto journey. With BRETT, every trade feels like a celebration on the blockchain dance floor.
            </p>
            <p className=" font-NeueMachinabold text-[20px] md:text-base break-words uppercase mt-5">
              Official Brett on Pulsechain
            </p>
          </div>
          <div className="w-1/2 md:w-full">
            <img
              src="/assets/images/Brett.gif"
              alt="Line"
              srcset=""
              className="mt-5 md:mx-auto md:size-1/2"
            />
          </div>
        </div>
        {/* ANDY */}
        <div className="flex flex-row-reverse md:flex-col items-center md:items-center md:justify-center mt-20 md:mt-10">
          <div className="w-1/2 md:w-full md:text-center">
            <h1 className="uppercase font-MonumentExtended text-[64px] md:text-xl md:mb-10 mb-10">
              ANDY
            </h1>
            <p className=" font-NeueMachinabold text-[20px] md:text-base  uppercase">
              The laid-back and easygoing friend, ANDY is the voice of reason in the Boy's Club. With a calm demeanor and witty observations, ANDY represents stability in the volatile world of memecoins. $ANDY on PulseChain offers a relaxed approach to crypto, perfect for those who prefer a chill ride through the markets.
            </p>
            <p className=" font-NeueMachinabold text-[20px] md:text-base break-words uppercase mt-5">
              Official Andy on Pulsechain
            </p>
          </div>
          <div className="w-1/2 md:w-full">
            <img
              src="/assets/images/Andy.gif"
              alt="Andy"
              srcset=""
              className="mt-5 md:mx-auto "
            />
          </div>
        </div>

        {/* landwolf */}
        <div className="flex  md:flex-col items-center md:items-center md:justify-center mt-20 md:mt-10">
          <div className="w-1/2 md:w-full md:text-center">
            <h1 className="uppercase font-MonumentExtended text-[64px] md:text-xl md:mb-10 mb-10">
              landwolf
            </h1>
            <p className=" font-NeueMachinabold text-[20px] md:text-base  uppercase">
              The wild and unpredictable member of the Boy's Club, WOLF brings an energetic and adventurous spirit to the group. Always ready for action, WOLF embodies the untamed nature of crypto markets. On PulseChain, $WOLF aims to lead the pack with its fierce determination and loyalty to its holders.
            </p>
            <p className=" font-NeueMachinabold text-[20px] md:text-base break-words uppercase mt-5">
              Official Landwolf on Pulsechain
            </p>
          </div>
          <div className="w-1/2 md:w-full">
            <img
              src="/assets/images/Landwolf.gif"
              alt="Landwolf"
              srcset=""
              className="mt-5 md:mx-auto "
            />
          </div>
        </div>

        {/* bird dog */}
        <div className="flex flex-row-reverse md:flex-col items-center md:items-center md:justify-center mt-20 md:mt-10">
          <div className="w-1/2 md:w-full md:text-center">
            <h1 className="uppercase font-MonumentExtended text-[64px] md:text-xl md:mb-10 mb-10">
              Bird Dog
            </h1>
            <p className=" font-NeueMachinabold text-[20px] md:text-base  uppercase">
            The loyal and sometimes quirky companion, BDOG adds an element of unpredictability to the Boy's Club. On PulseChain, $BDOG represents the faithful friend in the crypto world, always by your side through market ups and downs, with a few surprises along the way.
            </p>
            <p className=" font-NeueMachinabold text-[20px] md:text-base break-words uppercase mt-5">
              The Official Bird Dog on Pulsechain
            </p>
          </div>
          <div className="w-1/2 md:w-full">
            <img
              src="/assets/images/Bdog.gif"
              alt="Line"
              srcset=""
              className="mt-5 md:mx-auto md:size-1/2"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Meet;
