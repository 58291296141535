import React from "react";
import BdogDetail from "./components/Bdog/BdogDetail";

const Bdog = () => {
  return (
    <div>
      <BdogDetail />
    </div>
  );
};

export default Bdog;
