import React from "react";
import PrimaryLayout from "../PrimaryLayout/PrimaryLayout";

const AndyDetail = () => {
  return (
    <PrimaryLayout>
      <div className="flex flex-row-reverse md:flex-col-reverse  items-center md:items-center md:justify-center xxl:px-4">
        <div className="w-1/2 md:w-full md:text-center md:mb-10">
          <h1 className="uppercase font-MonumentExtended text-[64px] md:text-3xl md:mb-10 mb-10">
            ANDY
          </h1>
          <p className=" font-NeueMachinabold text-[20px] md:text-base  uppercase">
            Matt Furie's Boy's Club series introduces a quirky ensemble of
            characters, including Landwolf, Brett, Andy, Bdog, and Pepe the
            Frog.
          </p>
          <p className=" font-NeueMachinabold text-[20px] md:text-base break-words uppercase mt-5">
            This group of teenage monster friends navigates a world filled with
            psychedelic adventures, childlike wonder, and mischievous antics.
          </p>
          <p className=" font-NeueMachinabold text-[20px] md:text-base  uppercase mt-5">
            $PEPE $WOLF $ANDY $BDOG $BRETT
          </p>
          <p className=" font-NeueMachinabold text-[20px] md:text-base  uppercase mt-5">
            The Original Boys Club on Blockchain.
          </p>
        </div>
        <div className="w-1/2 md:w-full">
          <img
            src="/assets/images/Andy.png"
            alt="Andy"
            srcset=""
            className="mt-5 md:mx-auto size-[70%] md:size-full"
          />
        </div>
      </div>
    </PrimaryLayout>
  );
};

export default AndyDetail;
