import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import {
  ArchiveBoxXMarkIcon,
  ChevronDownIcon,
  PencilIcon,
  Square2StackIcon,
  TrashIcon,
} from "@heroicons/react/16/solid";
import { TiMinus } from "react-icons/ti";
import { FiPlus } from "react-icons/fi";
export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [tab, setTab] = useState("");
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const handleClick = (e) => {
    setTab((prevTab) => (prevTab === e ? null : e));
  };

  return (
    <div className="bg-black font-MonumentExtended text-white uppercase fixed w-full top-0 z-50">
      <div className="max-w-[1500px] mx-auto py-7  flex justify-around items-center  xxl:px-4 ">
        <a href="/">
          <img
            src="/assets/images/BoysClub_01-1 1.png"
            alt="BoysClub_01"
            srcset=""
            className="md:w-1/2 cursor-pointer"
          />
        </a>
        <div className="flex items-center gap-10 lg:hidden ml-auto text-[20px]">
          <a href="/" className="cursor-pointer">
            Home
          </a>
          <a href="/pulsechain" className="cursor-pointer">
            CONTRACTS
          </a>
          <a
            href="https://bridge.mypinata.cloud/ipfs/bafybeiahfpwhfdjf6iw4mwv5ytj7b6x4nmlxhwyae5ymfjdvszm6q5t3bm/#/bridge"
            target="_blank"
            className="cursor-pointer"
          >
            BRIDGE
          </a>
          {/* <a href="/contact" className="cursor-pointer">
            Contact
          </a> */}

          {/* <div className="cursor-pointer">nft collections</div> */}
          {/* <div className="font-NeueMachinabold text-right uppercase">
            <Menu>
              <MenuButton className="uppercase font-MonumentExtended py-1.5   rounded-md flex items-center gap-2 font-semibold text-white  focus:outline-none ">
                Boys
                <ChevronDownIcon className="size-6 fill-white/60" />
              </MenuButton>
              <Transition
                enter="transition ease-out duration-75"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <MenuItems
                  anchor="bottom end"
                  className="w-60 z-[60] origin-top-right rounded-[20px] border border-white/5  dropdowen-menu p-3 text-sm/6 text-white [--anchor-gap:var(--spacing-1)] focus:outline-none"
                >
                  <MenuItem>
                    <a
                      href="/andy"
                      className="group my-5 text-[24px] font-NeueMachinabold flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                    >
                      <TiMinus className="size-4 fill-white" />
                      ANDY
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="/breet"
                      className="group my-5  font-NeueMachinabold text-[24px] flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                    >
                      <TiMinus className="size-4 fill-white" />
                      BRETT
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="/pepe"
                      className="group my-5 font-NeueMachinabold text-[24px] flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                    >
                      <TiMinus className="size-4 fill-white" />
                      PEPE
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="/landwolf"
                      className="group my-5 font-NeueMachinabold text-[24px] flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                    >
                      <TiMinus className="size-4 fill-white" />
                      LANDWOLF
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="/birddog"
                      className="group my-5 font-NeueMachinabold text-[24px] flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                    >
                      <TiMinus className="size-4 fill-white" />
                      BDOG
                    </a>
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          </div> */}
          {/* <div className="font-NeueMachinabold text-right uppercase">
            <Menu>
              <MenuButton className="uppercase font-MonumentExtended py-1.5  rounded-md flex items-center gap-2 font-semibold text-white focus:outline-none  data-[focus]:outline-1 data-[focus]:outline-white">
                CONTRACT'S
                <ChevronDownIcon className="size-6 fill-white/60" />
              </MenuButton>
              <Transition
                enter="transition ease-out duration-75"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <MenuItems
                  anchor="bottom end"
                  className="w-60 z-[60] origin-top-right rounded-[20px] border border-white/5  dropdowen-menu p-3 text-sm/6 text-white [--anchor-gap:var(--spacing-1)] focus:outline-none"
                >
                  <MenuItem>
                    <a
                      href="/pulsechain"
                      className="group my-5 text-[24px] uppercase font-NeueMachinabold flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                    >
                      <TiMinus className="size-4 fill-white " />
                      pulsechain
                    </a>
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          </div> */}
          {/* <div className="font-NeueMachinabold text-right uppercase">
            <Menu>
              <MenuButton className="uppercase font-MonumentExtended py-1.5   rounded-md flex items-center gap-2 font-semibold text-white focus:outline-none  data-[focus]:outline-1 data-[focus]:outline-white">
                BRIDGE
                <ChevronDownIcon className="size-6 fill-white/60" />
              </MenuButton>
              <Transition
                enter="transition ease-out duration-75"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <MenuItems
                  anchor="bottom end"
                  className="w-60 z-[60] origin-top-right rounded-[20px] border border-white/5  dropdowen-menu p-3 text-sm/6 text-white [--anchor-gap:var(--spacing-1)] focus:outline-none"
                >
                  <MenuItem>
                    <a
                      href="/bridgepulsechain"
                      className="group my-5 text-[24px] uppercase font-NeueMachinabold flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10"
                    >
                      <TiMinus className="size-4 fill-white " />
                      pulsechain
                    </a>
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          </div> */}
        </div>

        <div className="hidden lg:flex ml-auto ">
          <GiHamburgerMenu fontSize={25} onClick={toggleDrawer} />
        </div>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="left"
          className="bla bla bla relative"
        >
          <div className="absolute text-white right-4 top-5">
            <MdClose fontSize={28} onClick={() => setIsOpen(false)} />
          </div>
          <div className="px-5 py-10 text-lg space-y-10 mt-5 ">
            <div className="flex flex-col space-y-5 text-white">
              <a href="/pulsechain" className="cursor-pointer">
                CONTRACTS
              </a>
              <a
                href="https://bridge.mypinata.cloud/ipfs/bafybeiahfpwhfdjf6iw4mwv5ytj7b6x4nmlxhwyae5ymfjdvszm6q5t3bm/#/bridge"
                target="_blank"
                className="cursor-pointer"
              >
                BRIDGE
              </a>
              {/* <div
                className="cursor-pointer flex items-center justify-between"
                onClick={() => handleClick("boys")}
              >
                <div> Boys</div>
                <div> {tab == "boys" ? <TiMinus /> : <FiPlus />}</div>
              </div>
              {tab === "boys" && (
                <ul className="flex flex-col list-disc list-inside space-y-5 indent-4">
                  <li>
                    <a className="uppercase " href="/andy">
                      Andy
                    </a>
                  </li>
                  <li>
                    <a className="uppercase " href="/breet">
                      breet
                    </a>
                  </li>
                  <li>
                    <a className="uppercase " href="/pepe">
                      pepe
                    </a>
                  </li>
                  <li>
                    <a className="uppercase " href="/landwolf">
                      landwolf
                    </a>
                  </li>
                  <li>
                    <a className="uppercase " href="/birddog">
                      bdog
                    </a>
                  </li>
                </ul>
              )}

              <div
                className="cursor-pointer flex items-center justify-between"
                onClick={() => handleClick("Chains")}
              >
                <div> Chains</div>
                <div> {tab == "Chains" ? <TiMinus /> : <FiPlus />}</div>
              </div>

              {tab === "Chains" && (
                <ul className="flex flex-col list-disc list-inside space-y-5 indent-4">
                  <li>
                    <a className="uppercase " href="/pulsechain">
                      Pluschain
                    </a>
                  </li>
                </ul>
              )}
              <div
                className="cursor-pointer flex items-center justify-between"
                onClick={() => handleClick("Bridge")}
              >
                <div> Bridge</div>
                <div> {tab == "Bridge" ? <TiMinus /> : <FiPlus />}</div>
              </div>

              {tab === "Bridge" && (
                <ul className="flex flex-col list-disc list-inside space-y-5 indent-4">
                  <li>
                    <a className="uppercase " href="/bridgepulsechain">
                      Pluschain
                    </a>
                  </li>
                </ul>
              )}*/}
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  );
}
