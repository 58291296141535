import React from "react";

export default function Club() {
  return (
    <div className="clubbg flex min-h-screen bg-center bg-cover ">
      <div className="max-w-7xl mx-auto flex lg:flex-col items-center xl:px-4">
        <div className="w-[65%] lg:w-full">
          <h1 className="font-MonumentExtendedUltrabold lg:text-center uppercase text-[80px] md:text-2xl xl:mb-10">
            welcome to BOYS CLUB
          </h1>
          <img
            src="/assets/images/club.png"
            alt="ezgif"
            srcset=""
            // className="size-[80%] ml-[250px] lg:ml-0 lg:mx-auto lg:size-full lg:mt-0 -mt-10 z-10 relative rotate-12 xl:rotate-0"
            className="size-[50%] md:size-auto lg:mx-auto lg:mt-0 mx-auto xl:my-10 z-10 relative flex justify-center"
          />
        </div>
        <div className="relative w-[35%] lg:w-full self-center ">
          <div className="p-10 gradiant-club-box rounded-[30px] font-NeueMachinaregular self-center ">
            <p className="text-center leading-10 ">
            Matt Furie's "Boy's Club" comic series, born in the mid-2000s, introduced four slacker roommates: Andy, Brett, Landwolf, and the now-infamous Pepe the Frog. This cult classic of psychedelic humor and laid-back antics gained widespread popularity, with Pepe becoming an unexpected internet phenomenon. Furie's creation has since inspired The Boys Club tokens on PulseChain—ANDY, WOLF, BDOG, BRETT, and PEPE—reflecting the original characters in the blockchain ecosystem. This digital adaptation demonstrates how Furie's imaginative world continues to evolve, bridging the gap between underground comics and modern crypto culture, showcasing the enduring influence of his creations from their humble zine origins to global meme status. 
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
