import React, { useState, useEffect } from "react";
const tokenAddresses = [
  "0x495d9b70480A22a82D0FB81981480764BA55550e",
  "0xa219C24353B59bEE9F535C4402c11C476A49ACA9",
  "0x05cb9A4C644d9d6bc07430a220E1B5CE11Cd2eaa",
  "0x64Cdf1E81136023ad912ED1522309fDa05322187",
  "0x3E59B2aeAe49674CF0CB21bf200277f3CcD232DD",
  "0x11659a752AC07452B9F139B897c917338e2dFB16",
];
const dexscreenerBaseUrl = "https://api.dexscreener.com/latest/dex/tokens/";
export default function Tokenomics() {
  const [totalFDV, setTotalFDV] = useState(0);
  const formatPrice = (price) => {
    const numericPrice = Number(price);
    if (isNaN(numericPrice)) {
      return "N/A";
    }
    return numericPrice.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  useEffect(() => {
    const fetchPromises = tokenAddresses.map(async (tokenAddress) => {
      try {
        const response = await fetch(`${dexscreenerBaseUrl}${tokenAddress}`);
        const tokenData = await response.json();
        if (tokenData.pairs && tokenData.pairs.length > 0) {
          const { fdv } = tokenData.pairs[0];
          const numericFDV = Number(fdv);
          if (!isNaN(numericFDV)) {
            setTotalFDV((prevTotal) => prevTotal + numericFDV);
          }
        }
      } catch (error) {
        console.error("Failed to fetch data for address", tokenAddress, error);
      }
    });
    Promise.all(fetchPromises).then(() => {
      console.log("Total FDV:", totalFDV);
    });
  }, []); // Empty dependency array to run effect only once

  return (
    <div className="xl:px-4 my-20 border rounded-[30px] py-10 flex flex-col justify-center gradiant-faq-box relative">
      <img
        src="/assets/images/Ellipse.png"
        alt="Ellipse"
        srcset=""
        className="absolute inset-0 object-cover h-full w-full -z-10"
      />
      <div className="flex lg:flex-col justify-center items-center gap-10">
        <div>
          <div className="text-[60px] md:text-2xl font-MonumentExtendedUltrabold text-center text-primary">
            {formatPrice(totalFDV)}
          </div>
          <h1 className="text-[50px] md:text-xl font-NeueMachinabold text-center text-white">
            TOTAL MARKET CAP
          </h1>
          <h1 className="text-[40px] md:text-lg font-NeueMachinaregular text-center text-slate-400">
            TOKENOMICS
          </h1>
        </div>

        <div>
          <ul className="list-disc list-inside mx-auto  uppercase font-NeueMachinaregular space-y-4">
            <li>fixed supply : 420,690,000 (wolf,andy,bdog,brett)</li>
            <li>contracts renounced and verified</li>
            <li>initial lp's burned with 100% of supply</li>
            <li>zero buy sell taxes</li>
            <li>ribbetting thesis</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
