import React from "react";
import BreetDetail from "./components/Breet/BreetDetail";

const Breet = () => {
  return (
    <div>
      <BreetDetail />
    </div>
  );
};

export default Breet;
