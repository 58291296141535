import React from "react";
import LandwolfDetail from "./components/Landwolf/LandwolfDetail";

const Landwolf = () => {
  return (
    <div>
      <LandwolfDetail />
    </div>
  );
};

export default Landwolf;
