import React from "react";
import PrimaryLayout from "../PrimaryLayout/PrimaryLayout";

const PepeDetail = () => {
  return (
    <PrimaryLayout>
      <div className="flex flex-row-reverse md:flex-col-reverse items-center md:items-center md:justify-center xxl:px-4 md:mb-20">
        <div className="w-1/2 md:w-full md:text-center">
          <h1 className="uppercase font-MonumentExtended text-[64px] md:text-3xl md:mb-10 md:mt-10 mb-10">
            PEPE
          </h1>
          <p className=" font-NeueMachinabold text-[20px] md:text-base  uppercase  break-all">
            The most memeable memecoin in existence. Pepe had their day on ETH,
            it’s time for Pepe on Pulsechain to take reign.
          </p>
          <p className=" font-NeueMachinabold text-[20px] md:text-base break-words uppercase mt-5  break-all">
            Pepe is tired of watching everyone play hot potato with the endless
            derivative ShibaCumGMElonKishuTurboAssFlokiMoon Inu coins. The Inu’s
            have had their day. It’s time for the most recognizable meme in the
            world to take his reign as king of the memes.
          </p>
          <p className=" font-NeueMachinabold text-[20px] md:text-base  uppercase mt-5  break-all">
            Pepe is here to make memecoins great again. Forked from ETH to PLS
            on mainnet launch, $PEPE is a coin for the people, forever. Fueled
            by pure memetic power, let $PEPE show you the way.
          </p>
        </div>
        <div className="w-1/2 md:w-full">
          <img
            src="/assets/images/Pepe_Walking.png"
            alt="Pepe_Walking"
            srcset=""
            className="mt-5 md:mx-auto size-[70%] md:size-full"
          />
        </div>
      </div>
    </PrimaryLayout>
  );
};

export default PepeDetail;
