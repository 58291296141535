import "./App.css";
import Club from "./components/Club/Club";
import CommingSoon from "./components/CommingSoon/CommingSoon";
import Faq from "./components/Faq/Faq";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import Meet from "./components/Meet/Meet";
import Roadmap from "./components/Roadmap/Roadmap";
import Social from "./components/Social/Social";
import Team from "./components/Team/Team";
import Tokenomics from "./components/Tokenomics/Tokenomics";

function App() {
  return (
    <div className="">
      <Header />
      <div className="pt-[138px] md:pt-[97px]">
        <Hero />
        <div className="max-w-7xl mx-auto xl:px-4">
          {/* <Faq /> */}
          <Tokenomics />
        </div>
        <Club />

        <Meet />

        {/* <Roadmap /> */}
        <div className="max-w-7xl mx-auto xl:px-4">
          <CommingSoon />
          {/* <Team /> */}
          <Social />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
