import React from "react";
import PrimaryLayout from "../PrimaryLayout/PrimaryLayout";

const BreetDetail = () => {
  return (
    <PrimaryLayout>
      <div className="flex  md:flex-col-reverse items-center md:items-center md:justify-center xxl:px-4">
        <div className="w-1/2 md:w-full md:text-center">
          <h1 className="uppercase font-MonumentExtended text-[64px] md:text-3xl md:mb-10 md:mt-10 mb-10">
            Brett
          </h1>
          <p className=" font-NeueMachinabold text-[20px] md:text-base  uppercase">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Consectetur modi odit ipsa sit perferendis, labore ratione dicta
            libero aut atque quibusdam similique aliquid eius omnis a id, quidem
            nemo sint.
          </p>
        </div>
        <div className="w-1/2 md:w-full">
          <img
            src="/assets/images/ezgif2.gif"
            alt="brett"
            srcset=""
            className="mt-5 md:mx-auto md:size-1/2"
          />
        </div>
      </div>
    </PrimaryLayout>
  );
};

export default BreetDetail;
